import './App.css';

function App() {
  return (
    <div className="App">
          <h1>Blogging site</h1>
    </div>
  );
}

export default App;
